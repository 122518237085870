<template>
  <div>

    <div class="sliderWrap">
      <slider
        ref="slider"
        :options="options"
      >
        <slideritem>
          <div class="container blockInner">
            <img
              class="img1 animate__animated animate__fadeInDown"
              src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/multipleService02.svg"
            >
            <div class="animate__animated animate__fadeInDown">
              <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/multipleService01.svg" alt="">
              <!-- <h2> 基于版权区块链的存证 </h2>
              <h4> 数据直通互联网法院 <br> 司法认可的版权区块链存证 </h4>
              <div class="btnWrap">
                <router-link :to="{path:'/ExistingEvidence/InputForm'}">
                    <a-button type="primary" size="large">去存证</a-button>
                </router-link>
                
              </div> -->
            </div>
          </div>
        </slideritem>
       
        <div slot="loading">loading...</div>
      </slider>
    </div>
    <div class="collapse_div">
      <div class="topTittle animate__animated animate__fadeInDown">
        <h2>DCI版权服务</h2>
      </div>
      <div class="fluid container">
        <a-row class="collapse_ul">
            <a-col class="ul_l" :md="24" :lg="24" :xl="12">
              <h3>中国知链DCI版权服务</h3>
              <p>中国知链DCI版权服务是由教育部、中国知链和中国版权保护中心（即数字作品版权唯一标识符管理机构）合作，按照《中华人民共和国著作权法》、“数字作品版权唯一标识符”标准及相关规定，利用区块链和大数据、人工智能等技术，对数字作品版权进行保护，提供用户注册、DCI登记服务、DCI授权服务和DCI维权服务。 </p>
              <p>DCI登记成功的作品信息和版权权属确认信息将会保存在区块链中，保证所有的版权信息可信，可回溯。中国知链为数字网络化作品版权颁发永久的DCI标（数字作品版权唯一标识符）及作品登记证书（电子版），提供国家法律认可的版权确权、侵权申诉服务，帮助用户结算版税。 </p>
              <p>中国知链DCI版权服务面向个人和机构提供基于DCI标准的全流程数字作品版权服务，构建数字作品版权权属确认、授权结算、维权保护新体系。</p>
            </a-col>
            <a-col class="ul_r" :md="24" :lg="24" :xl="12">
              <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/multipleService01.png" alt="">
            </a-col>
        </a-row>
      </div>
    </div>
    

    <service-block></service-block>

    <div class="SolveBlocks"  ref="SolveBlocks">
      <div class="fluid container">
        <div class="topTittle animate__animated animate__fadeInDown">
          <h2>中国知链提供哪些服务</h2>
        </div>
        <img
        class="animate__animated animate__fadeInDown"
          src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/servicePic.png"
          width="100%"
        >
      </div>
    </div>

   <vip-block></vip-block>

    <link-block></link-block>

  </div>
</template>

<script>
import ServiceBlock from './components/ServiceBlock'
import VipBlock from './components/VipBlock'
import LinkBlock from './components/LinkBlock'
import { slider, slideritem } from 'vue-concise-slider'
export default {
  name: 'Service',
  components: {
    ServiceBlock,
    slider,
    slideritem,
    ServiceBlock,
    VipBlock,
    LinkBlock
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      //Slider configuration [obj]
      options: {
        currentPage: 0
      }
    }
  },

  methods: {
    init () {
      // this.axiosPost("/export/export/unLoadNum",{'user':'gff','pwd':'111'})
      //             .then(res => {
      //                  console.log("response...")
      //             })
    }
  }
}
</script>
<style lang="scss" scoped>
.sliderWrap {
  .multipleServiceBg{
    width: 100%;
  }
  background: #f7f8fb url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/multipleServiceBg.png') no-repeat 50% 100%;
  background-size: 100% 100%;
  height: 500px;
  /deep/ .slider-pagination {
    display: none;
  }
  /deep/ .slider-pagination-bullet {
    width: 56px;
    display: inline-block;
    height: 4px;
    border-radius: 1px;
    background: rgba($color: #fff, $alpha: 0.25);
  }
  /deep/ .slider-pagination-bullet-active {
    background: rgba($color: #fff, $alpha: 0.65);
  }
  .blockInner {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    & > div:first-child {
      position: relative;
      z-index: 100;
    }
  }
  h2{
     font-size: 48px;
     color:rgba($color: #fff, $alpha: .9)
  }
  h4{
    font-weight: 400;
    font-size: 20px;
    color:rgba($color: #fff, $alpha: .65);
    margin-top:24px;
  }
  .btnWrap {
    margin-top: 32px;
    .ant-btn {
      margin-right: 24px;
    }
  }
  .img1 {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-top: -260px;
    width: 600px;
    z-index: 99;
  }
}
.collapse_div{
  padding: 72px 0;
  h2 {
      font-size: 32px;
      text-align: center;
      margin-bottom: 20px;
    }
  .collapse_ul{
    background: #fff;
    padding: 50px;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.08);
    border-radius: 8px 0 0 8px;
    .ul_l{
      h3{
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        padding-bottom: 20px;
        padding-left: 20px;
        position: relative;
      }
      h3::before{
        content: '';
        width: 6px;
        height: 24px;
        border-radius: 2px;
        background: #007DFF;
        position: absolute;
        left: 0;
        top: 3px;
      }
      p{
        color: rgba(0, 18, 22, 0.65);
        text-indent: 2em;
        padding: 10px 0;
        line-height:1.769230769230769;
      }
      img{
        margin: 20px 0;
        max-width: 100%;
      }
    }
    .ul_r{
      img{
        width: 100%;
        padding: 50px 0 50px 30px;
      }
    }
  }
}


.SolveBlocks {
  background: #f7f9fa;
  padding: 72px 0;
  color:#fff;
  .topTittle {
    text-align: center;
    margin-bottom: 24px;
    h2 {
      font-size: 32px;
    }
  }
}

@media (min-width: 1200px) {
  .sliderWrap {
    height: 500px;
    .blockInner {
      h2 {
        font-size: 40px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .btnWrap {
      margin-top: 24px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px){
  .sliderWrap{
       height:auto;
      .blockInner{
        display: block;
        padding:24px 0;
        margin-top: 32px;
        h2{font-size: 36px;}
        h4{font-size: 18px;    margin-top: 8px;}
      }
      .img1{
        width: 500px;
        position: absolute;
        margin-top: 0;
        top: 50px;
        right: -40px;
      }
      .btnWrap{
        margin-top:24px;
      }
    }
}
@media screen and (min-width: 1px) and (max-width: 767px) {
  .sliderWrap{
    height:auto;
    .blockInner{
       display: block;
       padding: 16px;
       margin-top: 32px;
       position: relative;
       h2{font-size: 24px;color: #fff;}
       h4{
         font-size: 16px;color: rgba(255, 255, 255, 0.75);
         margin-top: 8px;
       }
    }
    .img1{
    width: 90%;
    position: absolute;
    margin-top: 0px;
    top: 80px;
    left: 16px;
    }
    .img2{
      display: none;
    }
    .btnWrap{
      margin-top:24px;
    }
    .ant-btn-lg{font-size: 14px;}
  }
}
</style>