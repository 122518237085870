<template>
  <div class="VIPBlocks" :class="{'VipHome':isVipHome}">
    <div class="fluid container">
      <div class="topTittle" v-if="!isVipHome">
        <h2>中国知链会员体系服务</h2>
      </div>
      <a-row>
        <a-col
          :md="24"
          :lg="24"
          :xl="8"
          v-for="(item,index) in vipConfigList"
          :key="index"
        >
          <div class="block">
            <div class="topWrap">
              <div>
                 <div class="bigTt">{{item.title}}</div>
                 <!-- <div class="priceWrap"><span class="price">{{item.amount}}</span>元/年</div> -->
                 <div class="priceWrap"><span class="price">待议</span>元/年</div>
              </div>
            </div>
            <div class="desc">
                <ul  v-if="!vipInfo">
                    <li  v-for="(itemChild,indexChild) in item.descList" :key="indexChild">
                      <span>{{itemChild.label}}</span>
                      <span>{{itemChild.num}}次</span>
                    </li>
                </ul>
                <div class="btnWrap">
                    <el-button type="primary" @click="onGoPay(item.type)">{{!vipInfo?'开通会员':'立即开通'}}</el-button>
                </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'ServiceBlock',
     props: {
    // 是否为VIP主页
    isVipHome: {
      type: [Boolean],
      default: false
    }
  },
  data () {
    return {
      list:[
        {
          tittle:'白银会员',
          price:'199',
          descList:[
            {
              label:'版权存证',
              num:'5'
            },
            {
              label:'电子版权登记',
              num:'1'
            },
            {
              label:'文字监测',
              num:'5'
            },
            {
              label:'下载监听报告',
              num:'1'
            },
            {
              label:'版权取证',
              num:'5'
            },
            {
              label:'电子公证',
              num:'5'
            }
          ]
        },
         {
          tittle:'黄金会员',
          price:'998',
          descList:[
            {
              label:'版权存证',
              num:'50'
            },
            {
              label:'电子版权登记',
              num:'10'
            },
            {
              label:'文字监测',
              num:'5'
            },
            {
              label:'下载监听报告',
              num:'1'
            },
            {
              label:'版权取证',
              num:'5'
            },
            {
              label:'电子公证',
              num:'5'
            }
          ]
        },
         {
          tittle:'钻石会员',
          price:'199',
          descList:[
            {
              label:'版权存证',
              num:'5'
            },
            {
              label:'电子版权登记',
              num:'1'
            },
            {
              label:'文字监测',
              num:'5'
            },
            {
              label:'下载监听报告',
              num:'1'
            },
            {
              label:'版权取证',
              num:'5'
            },
            {
              label:'电子公证',
              num:'5'
            }
          ]
        },
      ],
      refName:'ServiceBlock'
    }
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "vipConfig","vipInfo"]),
    vipConfigList: function () {
      let vipConfig = this.vipConfig;
      if (!vipConfig) {
        return [];
      }
      let arr = [];
      let config = vipConfig.S;
      config.title='白银会员';
      config.type='S';
      arr.push(config);
      config = vipConfig.G;
      config.type='G';
      config.title='黄金会员';
      arr.push(config);
      config = vipConfig.D;
      config.type='D';
      config.title='钻石会员';
      arr.push(config);
      return arr;
    },
  },
  methods: {
    onGoPay(type){
      if(this.isVipHome){
        this.$emit('go-pay',type)
      }else{
        this.$router.push({
            path: "/Vip",
            query: {}
          });
      }
     
    }
  }
}
</script>

<style lang="scss">

.VIPBlocks{
  //   background: #f7f8fb url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/guaranteeBg.png') no-repeat 50% 50%;
  // background-size: 100% auto;
   background-image: linear-gradient(0deg, #007DFF 20%, #145FED 80%);
    padding: 72px 0;
  .topTittle {
    text-align: center;
    margin-bottom: 24px;
    h2 {
      font-size: 32px;
         color:#fff;
    }
  }
  .block {
    border-radius: 4px;
    overflow: hidden;
       .el-button--primary, .el-button--primary:focus, .el-button--primary:visited{
             background:#89ABBD;
             border-color:#89ABBD;
           }
            .el-button--primary:hover{
                background:#9fbecf;
             border-color:#9fbecf;
            }
    .topWrap{
      display: flex;
      align-items: center;
      justify-content: center;
      background:url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/serviceVIP1.png') no-repeat center;
      background-size: 100% auto;
      padding:28px 0;
      text-align: center;
      color:#365C69;
       .bigTt {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: bold;
      }
      .priceWrap{
        font-size: 18px;
        .price{
          font-size: 32px;
          font-weight: bold;
        }
      }
      img{
        width:44px;
        height: 44px;
        display: inline-block;
        margin-bottom: 8px;
      }
    }
   
    .desc {
      padding:8px 16px 32px;
      background:#fff;
      li{
        display: flex;
        justify-content: space-between;
        padding:4px 16px;
      }
       .btnWrap{
          margin-top:24px;
        display: flex;
        justify-content: center;
      }
    }
  }
  /deep/ .ant-row{
     .ant-col:nth-child(2){
       .block{
           .topWrap{
             background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/serviceVIP2.png');
             color:#C78317;
           }
           .el-button--primary, .el-button--primary:focus, .el-button--primary:visited{
             background:#E3A861;
             border-color:#E3A861;
           }
            .el-button--primary:hover{
                 background:#f3c286;
             border-color:#f3c286;
            }
       }
     }
     .ant-col:nth-child(3){
      .block{
           .topWrap{
             background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/serviceVIP3.png');
             color:#08A4A0;
           }
            .el-button--primary, .el-button--primary:focus, .el-button--primary:visited{
             background:#007DFF;
             border-color:#007DFF;
           }
           .el-button--primary:hover{
                 background:#09e6e6;
             border-color:#09e6e6;
            }
       }
     }
  }
}
  .VipHome{
    background:none;
    padding:0 0 32px;
    .block{
        box-shadow: 0 0 16px rgba(0, 109, 117, 0.08);
    }
  }
@media screen and (min-width: 992px) and (max-width: 1199px){
  .VIPBlocks{
      .ant-col{    
        display: block;
        box-sizing: border-box;
        width: 33.33333333%;
        }
      .block{
        .topWrap{
            background-size:auto 100%;
            padding:28px 0;
        }
    
      }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px){
  .VIPBlocks{
      .ant-col{    
        display: block;
        box-sizing: border-box;
        width: 100%;
        }
      .block{
        .topWrap{
            background-size: 100% auto;
            padding:28px 0;
        }
    
      }
  }
}
@media screen and (min-width: 1px) and (max-width: 767px) {
  .VIPBlocks{
      .block{
        .topWrap{
            background-size:100% auto;
            padding:24px 0;
        }
    
      }
  }
}

</style>
