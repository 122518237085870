<template>
  <div class="ServiceBlocks">
    <div class="fluid container">
      <div class="topTittle">
        <h2>中国知链提供哪些服务</h2>
      </div>
      <a-row>
        <a-col
          :md="24"
          :lg="12"
          :xl="6"
          v-for="(item,index) in list"
          :key="index"
        >
          <div class="block"
           :ref="refName+index"
          :class="{'animate__animated':refArray[refName+index],'animate__fadeInDown':refArray[refName+index]}">
            <div class="topWrap">
              <div>
                 <img :src="'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/stepIcon'+(index+1)+'.svg'">
                 <h3>{{item.tittle}}</h3>
              </div>
            </div>
            <div class="desc">
              {{item.desc}}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { scrollAnimate } from '../../../mixins'
export default {
  name: 'ServiceBlock',
   mixins: [scrollAnimate],
  data () {
    return {
      list:[
        {
          tittle:'版权存证',
          desc:'区块链存证，直通互联网法院'
        },
        {
          tittle:'版权登记',
          desc:'版权官方登记证书'
        },
        {
          tittle:'版权监测',
          desc:'作品在线监测，全网搜查'
        },
        {
          tittle:'版权取证',
          desc:'区块链上链记录，浏览器可查'
        },
      ],
      refName:'ServiceBlock'
    }
  },

  methods: {
 
  }
}
</script>

<style lang="scss">

.ServiceBlocks {
  background: #fff;
  background-size: 100% auto;
  padding: 72px 0;
  .topTittle {
    text-align: center;
    margin-bottom: 24px;
    h2 {
      font-size: 32px;
    }
  }
  .block {
    background:#fff;
    margin-top:32px;
    .topWrap{
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: 100% auto;
      text-align: center;
       h3 {
        margin-bottom: 8px;
        font-size: 18px;
      }
      img{
        width:96px;
        height: 96px;
        display: inline-block;
        margin-bottom: 8px;
      }
    }
   
    .desc {
      padding:4px 16px;
      color: #012b35;
      text-align: center;
    }
  }
  
 
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .ServiceBlocks{
    .ant-col{width: 50%;}
    .block{
  
    }
  }
}
@media screen and (min-width: 1px) and (max-width: 767px) {
  .ServiceBlocks{
    .ant-col{}
    .block{
  
    }
  }
}

</style>
