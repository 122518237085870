<template>
  <div class="LinkBlock">
    <div class="fluid container">
      <div class="topTittle">
        <h2>中国知链合作伙伴</h2>
      </div>
      <a-row>
        <a-col
          :md="24"
          :lg="12"
          :xl="12"
          v-for="(item,index) in list"
          :key="index"
        >
          <div class="block"
           :ref="refName+index"
          :class="{'animate__animated':refArray[refName+index],'animate__fadeInDown':refArray[refName+index]}">
<div class="topWrap">
              <div class="logo-link">
                 <img :src="'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/logo-link'+(index+1)+'.png'">
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { scrollAnimate } from '../../../mixins'
export default {
  name: 'LinkBlock',
   mixins: [scrollAnimate],
  data () {
    return {
      list:[
        {},{},{},{},
      ],
      refName:'ServiceBlock'
    }
  },

  methods: {
 
  }
}
</script>

<style lang="scss">

.LinkBlock {
  background: #fff;
  background-size: 100% auto;
  padding: 72px 0;
  .topTittle {
    text-align: center;
    margin-bottom: 24px;
    h2 {
      font-size: 32px;
    }
  }
  .block {
    background:#EEEEEE;
    height: 120px;
  }
  .logo-link{
    
    img{width: 100%;}
  }
  
 
}

@media (max-width: 1200px) {
  .LinkBlock {
      .block{
    
      }
  }
}


</style>
